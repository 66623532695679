import classNames from 'classnames';
import React from 'react';

export function Label({
    htmlFor,
    className,
    children,
    required,
}: React.PropsWithChildren<{
    htmlFor?: string;
    className?: string | undefined;
    required?: boolean;
}>) {
    return (
        <label htmlFor={htmlFor} className={classNames('mb-1 block text-sm', className)}>
            {children} {required && '*'}
        </label>
    );
}
