export function roundWithPrecisionOrUndefined(number: number | undefined) {
    if (typeof number === 'undefined') return undefined;
    return roundWithPrecision(number);
}

export function roundWithPrecision(number: number, precision: number = 4) {
    return Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function convertCentToEuro(number: number) {
    return roundWithPrecision(number / 100);
}
