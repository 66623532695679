import { useIntl } from 'react-intl';
import { HourGlassIcon, SuccessTickIcon, WarningTriangleIcon } from './Icons.tsx';

export const Status = ({ type }: { type: string }) => {
    const intl = useIntl();
    switch (type) {
        case 'failed': {
            return (
                <div className="flex gap-2 items-center">
                    <WarningTriangleIcon width={20} height={20} />
                </div>
            );
        }
        case 'succeeded': {
            return (
                <div className="flex gap-2 items-center">
                    <SuccessTickIcon width={20} height={20} />
                </div>
            );
        }
        case 'running': {
            return (
                <div className="flex gap-2 items-center">
                    <HourGlassIcon width={20} height={20} />
                </div>
            );
        }
    }
};
