import { ExclamationTriangleIcon } from '@trawa-energy/ui-kit';
import { Children } from 'react';

export const ErrorBanner = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    return (
        Children.toArray(children).length > 0 && (
            <div
                role="alert"
                className="flex items-center justify-between py-4 px-3.5 rounded-lg gap-4 bg-warning-content text-gray-50 w-full"
            >
                <div className="flex items-center gap-4">
                    <ExclamationTriangleIcon />
                    <span>{children}</span>
                </div>
                <button className="btn btn-sm btn-circle btn-ghost">✕</button>
            </div>
        )
    );
};
