import { Link, useNavigate } from 'react-router-dom';
import { trpcBackoffice as trpc } from '../utils/trpc';
import { useMemo, useRef, useState } from 'react';
import { AccountForm } from './AccountForm';
import { config } from '../config';
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid';
import { fakeAccountIds } from './fakeAccountIds';

type Account = { id: string; name: string; temp_defaultPassword: string | null }; //inferProcedureOutput<typeof trpc.accounts.getMany>

export function Accounts() {
    const { data: accounts, refetch: refetchAccounts } = trpc.accounts.getMany.useQuery();
    const { data: users, refetch: refetchUsers } = trpc.users.getAll.useQuery({});
    const { mutateAsync: addDefaultUser } = trpc.users.addDefault.useMutation();

    const mappedAccounts = useMemo(
        () =>
            users &&
            accounts
                ?.map(account => ({
                    ...account,
                    isFake: fakeAccountIds.includes(account.id),
                    defaultUser: users.find(
                        user => user.email === `default-user+${account!.id.replace(/-/g, '')}@trawa.de`,
                    ),
                }))
                .sort((a, b) => (b.isFake ? 1 : 0) - (a.isFake ? 1 : 0) || a.name.localeCompare(b.name)),
        [users, accounts],
    );

    const [dialog, setDialog] = useState<HTMLDialogElement | null>(null);
    const navigate = useNavigate();
    return (
        <div className="space-y-5">
            <div className="flex justify-between items-center gap-4">
                <h3 className="text-lg">Accounts</h3>
                <button className="btn btn-primary btn-outline" type="button" onClick={() => dialog?.showModal()}>
                    Add account
                </button>
            </div>
            <table className="table table-sm">
                <tbody>
                    {mappedAccounts?.map(account => (
                        <tr key={account.id} className="hover cursor-pointer">
                            <td
                                className={account.isFake ? 'text-slate-500' : 'text-black'}
                                onClick={() => navigate(`/accounts/${account.id}`)}
                            >
                                {account.name} {account.isFake && '(demo)'}
                            </td>
                            <td>
                                {account.defaultUser ? (
                                    <a
                                        className="btn btn-sm btn-link btn-neutral"
                                        target="_blank"
                                        href={loginUrlWithPassword(account)}
                                    >
                                        dashboard
                                    </a>
                                ) : (
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={async e => {
                                            e.currentTarget.disabled = true;
                                            try {
                                                await addDefaultUser({ id: account.id });
                                                await refetchAccounts();
                                                await refetchUsers();
                                            } finally {
                                                if (e.currentTarget) {
                                                    e.currentTarget.disabled = false;
                                                }
                                            }
                                        }}
                                    >
                                        Add default user
                                    </button>
                                )}
                            </td>
                            <td>{account.defaultUser && <PasswordEmailSnippet account={account} />}</td>
                            <td>
                                {account.featureFlags.map(x => (
                                    <span key={x} className="badge badge-info">
                                        {x}
                                    </span>
                                ))}
                            </td>
                            <td>
                                <Link className="link link-primary" to={`/users?accountId=${account.id}`}>
                                    Users
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
                {!mappedAccounts && (
                    <tbody className="space-y-4">
                        {[...new Array(10)].fill(null).map((_, i) => (
                            <tr key={i} className="border-none">
                                <td>
                                    <div className="skeleton h-10 w-full"></div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </table>
            <dialog ref={setDialog} className="modal">
                <div className="modal-box">
                    <button
                        className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                        onClick={() => dialog?.close()}
                    >
                        ✕
                    </button>
                    <AccountForm
                        onSaved={() => {
                            dialog?.close();
                            refetchAccounts();
                        }}
                    />
                </div>
            </dialog>
        </div>
    );
}

function loginUrlWithPassword(account: Account): string {
    return new URL(
        `login/?` +
            new URLSearchParams({
                accountId: account.id,
                password: account.temp_defaultPassword ?? '',
                analyticsDisabled: 'true', // disables tracking
            }).toString(),
        config.portalBaseUrl,
    ).toString();
}

function loginUrlWithoutPassword(account: Account): string {
    return new URL(
        `login/?` +
            new URLSearchParams({
                accountId: account.id,
            }).toString(),
        config.portalBaseUrl,
    ).toString();
}

function PasswordEmailSnippet({ account }: { account: Account }) {
    const ref = useRef<HTMLDialogElement>(null);
    const messageRef = useRef<HTMLParagraphElement>(null);
    return (
        <div>
            <button className="btn btn-sm btn-outline" onClick={() => ref.current?.showModal()}>
                Password message
            </button>
            <dialog ref={ref} className="modal">
                <div className="modal-box">
                    <div className="flex justify-between">
                        <h3 className="font-bold text-lg mb-4 select-none">{account.name}</h3>
                        <button
                            className="btn btn-sm btn-outline"
                            onClick={() => {
                                copyToClipboard(messageRef.current!);
                            }}
                        >
                            Copy text <DocumentDuplicateIcon className="h-5" />
                        </button>
                    </div>

                    <p ref={messageRef} className="p-4 border">
                        Betreff: Neue Anmeldeinformationen für Ihren Unternehmenszugang
                        <br />
                        <br />
                        Liebe Kundin, lieber Kunde,
                        <br />
                        <br />
                        Deine neuen Zugangsdaten lauten:
                        <br />
                        Passwort: <strong>{account.temp_defaultPassword}</strong>
                        <br />
                        Einfach auf den folgenden <a href={loginUrlWithoutPassword(account)}>Link</a> klicken, um Dich
                        einzuloggen:
                        <br />
                        Bei Fragen sind wir gerne für Dich da.
                        <br />
                        <br />
                        Viele Grüße,
                        <br />
                        Dein trawa-Team
                    </p>

                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn btn-sm btn-outline">Ok</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>
    );
}

function copyToClipboard(elem: HTMLElement) {
    const range = document.createRange();
    range.selectNode(elem);
    const selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    selection.addRange(range);
    const text = selection.toString();
    navigator.clipboard.writeText(text);
    selection.removeAllRanges();
}
