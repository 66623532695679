import { useIntl } from 'react-intl';
import { SetStateAction, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

export const FinishedOfferModal = ({
    toggleModal,
    isOpen,
}: {
    toggleModal: React.Dispatch<SetStateAction<boolean>>;
    isOpen: boolean;
}) => {
    const { dealId } = useParams();
    const intl = useIntl();
    const dialogRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (isOpen) {
            dialogRef.current?.showModal();
        } else {
            dialogRef.current?.close();
        }
    }, [isOpen]);
    return (
        <dialog ref={dialogRef} className="modal">
            <div className="modal-box">
                <form method="dialog">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                <h3 className="font-bold text-lg">
                    {intl.formatMessage({ id: 'deals.createOffer.finishedOfferPrompt.title' })}
                </h3>
                <p className="py-4">{intl.formatMessage({ id: 'deals.createOffer.finishedOfferPrompt.message' })}</p>
                <form method="dialog" className="modal-backdrop flex gap-4">
                    <Link className={'btn btn-md'} to={`/deals/${dealId}/offers`}>
                        {intl.formatMessage({ id: 'deals.createOffer.finishedOfferPrompt.goToOverview' })}
                    </Link>
                    <button
                        className="btn btn-md"
                        onClick={() => {
                            dialogRef.current?.close();
                            toggleModal(false);
                        }}
                    >
                        {intl.formatMessage({ id: 'deals.createOffer.finishedOfferPrompt.newOffer' })}
                    </button>
                </form>
            </div>
        </dialog>
    );
};
