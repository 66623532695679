import { ReactNode, useEffect, useState } from 'react';
import { useCustomConfig } from '../hooks/useCustomConfig.ts';
import { CustomerConfig } from '@trawa-energy/offer-tool-api/deals/schemas';
import { CustomConfigContext } from './CustomConfigContext.tsx';

export const CustomConfigProvider = ({ children }: { children: ReactNode }) => {
    const { config: rawConfig, isConfigLoading, isConfigError, refetch } = useCustomConfig();
    const [config, setConfig] = useState<CustomerConfig | undefined>(undefined);

    useEffect(() => {
        setConfig(rawConfig);
    }, [rawConfig]);

    return (
        <CustomConfigContext.Provider value={{ config, isConfigLoading, isConfigError, refetch }}>
            {children}
        </CustomConfigContext.Provider>
    );
};
