export const validateAddress = (address_components: google.maps.GeocoderAddressComponent[]) => {
    const address = {
        postCode: '',
        location: '',
        houseNumber: '',
        street: '',
    };

    const errors: string[] = [];
    const addressTypes = ['postal_code', 'locality', 'street_number', 'route'];
    addressTypes.forEach(type => {
        const component = address_components.find(component => component.types.includes(type));
        if (!component) {
            return errors.push(type);
        }

        if (component.types.includes('postal_code')) {
            if (component.long_name.length !== 5) {
                errors.push('postal_code');
            }
            address['postCode'] = component.long_name;
        }

        if (component.types.includes('locality')) {
            address['location'] = component.long_name;
        }

        if (component.types.includes('street_number')) {
            address['houseNumber'] = component.long_name;
        }

        if (component.types.includes('route')) {
            address['street'] = component.long_name;
        }
    });

    if (errors.length > 0) {
        return { errors };
    }

    return { data: address };
};
