import {
    FieldErrors,
    FieldNamesMarkedBoolean,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { FormValues } from '../../pages/CreateOffer.tsx';
import { ExclamationTriangleIcon, InfoIcon } from '@trawa-energy/ui-kit';
import { TextInputField } from '../TextInputField.tsx';
import { Mixpanel } from '../../../utils/setupMixpanel.ts';
import { useIntl } from 'react-intl';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { CustomConfigContext } from '../../context/CustomConfigContext.tsx';
import { roundWithPrecision } from '../../utils/precisionUtils.ts';
import { useDefaultPriceOverviewValues } from '../../hooks/useDefaultPriceOverviewValues.ts';

export const CostOverviewFormPart = ({
    register,
    errors,
    watch,
    setValue,
    getValues,
    dirtyFields,
    yearlyConsumption,
    setDefaultPricingState,
}: {
    register: UseFormRegister<FormValues>;
    errors: FieldErrors<FormValues>;
    watch: UseFormWatch<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    getValues: UseFormGetValues<FormValues>;
    dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FormValues>>>;
    yearlyConsumption: number;
    setDefaultPricingState: Dispatch<SetStateAction<{ isLoading: boolean; isError: boolean }>>;
}) => {
    const intl = useIntl();
    const configContext = useContext(CustomConfigContext);
    const [showOverride, setShowOverride] = useState(false);

    const watchCostsOverview = watch('costsOverview');
    const watchIsSingleOfferMode = watch('costsOverview.isSingleOfferMode');
    const watchHasIncludedProvision = watch('general.hasIncludedProvision');
    const watchIsIndustrial = useRef<boolean | undefined>(
        configContext?.config?.contract_settings?.sector === 'industry',
    );

    const watchDuration = watch('general.duration');
    const watchStartDate = watch('general.startDate');
    const watchProducts = watch('products');

    const {
        defaultValues: defaultCostValues,
        setDefaultValues: setDefaultCostValues,
        resetDefaultValues: resetCostValues,
        isLoading: isLoadingPrices,
        isError: isErrorPrices,
    } = useDefaultPriceOverviewValues({
        duration: watchDuration,
        products: watchProducts.activated,
        startDate: watchStartDate,
        yearlyConsumption: yearlyConsumption ?? 0,
    });

    const getPpaWindPrice =
        typeof watchCostsOverview?.ppaPriceWind === 'undefined' ||
        typeof watchCostsOverview?.gooPrice === 'undefined' ||
        typeof watchCostsOverview?.tradeMarginFee === 'undefined'
            ? intl.formatMessage({
                  id: 'deals.createOffer.priceOverview.costs.notAvailable',
              })
            : intl.formatMessage(
                  { id: 'deals.createOffer.priceOverview.costs.ppaWind.totalPrice' },
                  {
                      totalPrice: roundWithPrecision(
                          Number(watchCostsOverview?.ppaPriceWind) +
                              Number(watchCostsOverview?.gooPrice) +
                              Number(watchCostsOverview?.tradeMarginFee),
                      ),
                  },
              );

    const getPpaWindSolar =
        typeof watchCostsOverview?.ppaPriceSolar === 'undefined' ||
        typeof watchCostsOverview?.gooPrice === 'undefined' ||
        typeof watchCostsOverview?.tradeMarginFee === 'undefined'
            ? intl.formatMessage({
                  id: 'deals.createOffer.priceOverview.costs.notAvailable',
              })
            : intl.formatMessage(
                  { id: 'deals.createOffer.priceOverview.costs.ppaSolar.totalPrice' },
                  {
                      totalPrice: roundWithPrecision(
                          Number(watchCostsOverview?.ppaPriceSolar) +
                              Number(watchCostsOverview?.gooPrice) +
                              Number(watchCostsOverview?.tradeMarginFee),
                      ),
                  },
              );

    useEffect(() => {
        setDefaultPricingState({ isLoading: isLoadingPrices, isError: isErrorPrices });
    }, [isErrorPrices, isLoadingPrices, setDefaultPricingState]);

    useEffect(() => {
        if (defaultCostValues) {
            setValue('costsOverview', defaultCostValues);
        }
    }, [setValue, defaultCostValues]);

    return (
        <fieldset className="my-8">
            {isLoadingPrices && (
                <div role="alert" className="alert alert-warning mb-4">
                    <InfoIcon />
                    <span>{intl.formatMessage({ id: 'deals.createOffer.priceOverview.alert' })}</span>
                </div>
            )}
            {isErrorPrices && (
                <div role="alert" className="alert alert-error my-4 bg-warning text-warning-content">
                    <ExclamationTriangleIcon />
                    <span>{intl.formatMessage({ id: 'deals.createOffer.priceOverview.error' })}</span>
                </div>
            )}
            <div className="flex flex-col p-4 gap-4 border rounded-2xl border-base-200 bg-base-200">
                <h2>{intl.formatMessage({ id: 'deals.createOffer.priceOverview.title' })}</h2>
                {showOverride && (
                    <label className="flex items-center cursor-pointer">
                        <input
                            {...register(`costsOverview.isSingleOfferMode`)}
                            type="checkbox"
                            className="checkbox mr-4"
                        />
                        <span className="label-text">
                            {intl.formatMessage({
                                id: `deals.createOffer.priceOverview.singleOfferMode`,
                            })}
                        </span>
                    </label>
                )}

                <div className="grid grid-cols-3 gap-x-8 gap-y-4">
                    <TextInputField
                        {...register('costsOverview.ppaPriceWind', {
                            required: intl.formatMessage({
                                id: 'deals.createOffer.priceOverview.costs.required',
                            }),
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.ppaWind.error.pattern',
                                }),
                            },
                            min: {
                                value: 0,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.ppaWind.error.min',
                                }),
                            },
                            max: {
                                value: 50,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.ppaWind.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.ppaWind.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.ppaWind.placeholder',
                        })}
                        showInput={showOverride}
                        bottomLeftLabel={getPpaWindPrice}
                        hasError={!!errors.costsOverview?.ppaPriceWind}
                        bottomLeftErrorLabel={errors.costsOverview?.ppaPriceWind?.message}
                    />
                    <TextInputField
                        {...register('costsOverview.ppaPriceSolar', {
                            required: intl.formatMessage({
                                id: 'deals.createOffer.priceOverview.costs.required',
                            }),
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.ppaSolar.error.pattern',
                                }),
                            },
                            min: {
                                value: 0,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.ppaSolar.error.min',
                                }),
                            },
                            max: {
                                value: 50,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.ppaSolar.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.ppaSolar.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.ppaSolar.placeholder',
                        })}
                        showInput={showOverride}
                        bottomLeftLabel={getPpaWindSolar}
                        hasError={!!errors.costsOverview?.ppaPriceSolar}
                        bottomLeftErrorLabel={errors.costsOverview?.ppaPriceSolar?.message}
                    />
                    <TextInputField
                        {...register('costsOverview.gooPrice', {
                            required: intl.formatMessage({
                                id: 'deals.createOffer.priceOverview.costs.required',
                            }),
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.HKN.error.pattern',
                                }),
                            },
                            min: {
                                value: 0,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.HKN.error.min',
                                }),
                            },
                            max: {
                                value: 50,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.HKN.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.HKN.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.HKN.placeholder',
                        })}
                        showInput={showOverride}
                        bottomLeftLabel={showOverride ? '' : defaultCostValues.gooPrice}
                        hasError={!!errors.costsOverview?.gooPrice}
                        bottomLeftErrorLabel={errors.costsOverview?.gooPrice?.message}
                    />
                    <TextInputField
                        {...register('costsOverview.tradeMarginFee', {
                            required: intl.formatMessage({
                                id: 'deals.createOffer.priceOverview.costs.required',
                            }),
                            pattern: {
                                value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.tradeMargin.error.pattern',
                                }),
                            },
                            min: {
                                value: -1,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.tradeMargin.error.min',
                                }),
                            },
                            max: {
                                value: 1,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.tradeMargin.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.tradeMargin.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.tradeMargin.placeholder',
                        })}
                        showInput={showOverride}
                        bottomLeftLabel={showOverride ? '' : defaultCostValues.tradeMarginFee}
                        hasError={!!errors.costsOverview?.tradeMarginFee}
                        bottomLeftErrorLabel={errors.costsOverview?.tradeMarginFee?.message}
                    />
                    <TextInputField
                        {...register('costsOverview.trawaInternalFee', {
                            required: intl.formatMessage({
                                id: 'deals.createOffer.priceOverview.costs.required',
                            }),
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.trawaInternalFee.error.pattern',
                                }),
                            },
                            min: {
                                value: 0,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.trawaInternalFee.error.min',
                                }),
                            },
                            max: {
                                value: 50,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.trawaInternalFee.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.trawaInternalFee.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.trawaInternalFee.placeholder',
                        })}
                        showInput={showOverride}
                        bottomLeftLabel={
                            showOverride
                                ? ''
                                : defaultCostValues.trawaInternalFee
                                  ? defaultCostValues.trawaInternalFee
                                  : intl.formatMessage({
                                        id: 'deals.createOffer.priceOverview.costs.notAvailable',
                                    })
                        }
                        hasError={!!errors.costsOverview?.trawaInternalFee}
                        bottomLeftErrorLabel={errors.costsOverview?.trawaInternalFee?.message}
                    />
                    <TextInputField
                        {...register('costsOverview.distributionFee', {
                            required: watchHasIncludedProvision
                                ? intl.formatMessage({
                                      id: 'deals.createOffer.priceOverview.costs.required',
                                  })
                                : false,
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.provision.error.pattern',
                                }),
                            },
                            min: {
                                value: 0,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.provision.error.min',
                                }),
                            },
                            max: {
                                value: 5,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.provision.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.provision.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.provision.placeholder',
                        })}
                        isDisabled={!watchHasIncludedProvision}
                        showInput={showOverride}
                        bottomLeftLabel={
                            showOverride
                                ? ''
                                : watchHasIncludedProvision && defaultCostValues.distributionFee
                                  ? defaultCostValues.distributionFee
                                  : 0
                        }
                        hasError={!!errors.costsOverview?.distributionFee}
                        bottomLeftErrorLabel={errors.costsOverview?.distributionFee?.message}
                    />
                    <TextInputField
                        {...register('costsOverview.meterCosts', {
                            required: watchIsIndustrial.current
                                ? false
                                : intl.formatMessage({
                                      id: 'deals.createOffer.priceOverview.costs.required',
                                  }),
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.meterCosts.error.pattern',
                                }),
                            },
                            min: {
                                value: 0,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.meterCosts.error.min',
                                }),
                            },
                            max: {
                                value: 25,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.meterCosts.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.meterCosts.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.meterCosts.placeholder',
                        })}
                        isDisabled={watchIsIndustrial.current}
                        showInput={showOverride}
                        bottomLeftLabel={
                            showOverride
                                ? ''
                                : !watchIsIndustrial.current && defaultCostValues.meterCosts
                                  ? defaultCostValues.meterCosts
                                  : 0
                        }
                        hasError={!!errors.costsOverview?.meterCosts}
                        bottomLeftErrorLabel={errors.costsOverview?.meterCosts?.message}
                    />
                    <TextInputField
                        {...register('costsOverview.maximumFixed', {
                            required: intl.formatMessage({
                                id: 'deals.createOffer.priceOverview.costs.required',
                            }),
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.maximumFixed.error.pattern',
                                }),
                            },
                            min: {
                                value: 0,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.maximumFixed.error.min',
                                }),
                            },
                            max: {
                                value: 1,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.maximumFixed.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.maximumFixed.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.maximumFixed.placeholder',
                        })}
                        showInput={showOverride}
                        bottomLeftLabel={showOverride ? '' : defaultCostValues.maximumFixed}
                        hasError={!!errors.costsOverview?.maximumFixed}
                        bottomLeftErrorLabel={errors.costsOverview?.maximumFixed?.message}
                    />
                    <TextInputField
                        {...register('costsOverview.spotExposureSingleOffer', {
                            required: intl.formatMessage({
                                id: 'deals.createOffer.priceOverview.costs.required',
                            }),
                            pattern: {
                                value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.spotExposure.error.pattern',
                                }),
                            },
                            min: {
                                value: 0,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.spotExposure.error.min',
                                }),
                            },
                            max: {
                                value: 1,
                                message: intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.costs.spotExposure.error.max',
                                }),
                            },
                        })}
                        label={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.spotExposure.label',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.createOffer.priceOverview.costs.spotExposure.placeholder',
                        })}
                        isDisabled={!watchIsSingleOfferMode}
                        showInput={showOverride}
                        bottomLeftLabel={showOverride ? '' : defaultCostValues.spotExposureSingleOffer}
                        hasError={!!errors.costsOverview?.spotExposureSingleOffer}
                        bottomLeftErrorLabel={errors.costsOverview?.spotExposureSingleOffer?.message}
                    />
                </div>
                <div className="flex gap-4">
                    {showOverride ? (
                        <>
                            <button
                                type="button"
                                className="btn btn-sm btn-outline btn-primary"
                                onClick={() => {
                                    setShowOverride(false);
                                    setValue('costsOverview', { ...defaultCostValues });
                                }}
                            >
                                {intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.cancelCTA',
                                })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-sm btn-outline btn-primary"
                                onClick={() => {
                                    resetCostValues();
                                }}
                            >
                                {intl.formatMessage({
                                    id: 'deals.createOffer.priceOverview.resetCTA',
                                })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-sm  btn-primary"
                                onClick={() => {
                                    if (errors?.costsOverview) {
                                        return;
                                    }
                                    setDefaultCostValues(getValues().costsOverview);
                                    const trackingProps = {
                                        'Wind PPA': dirtyFields.costsOverview?.ppaPriceWind,
                                        'Solar PPA': dirtyFields.costsOverview?.ppaPriceWind,
                                        HKN: dirtyFields.costsOverview?.gooPrice,
                                        'Single offer': dirtyFields.costsOverview?.isSingleOfferMode,
                                        'Spot exposure': dirtyFields.costsOverview?.spotExposureSingleOffer,
                                        'Distribution fee': dirtyFields.costsOverview?.distributionFee,
                                        'Service fee': dirtyFields.costsOverview?.trawaInternalFee,
                                        'Trade margin fee': dirtyFields.costsOverview?.tradeMarginFee,
                                        'Meter costs': dirtyFields.costsOverview?.meterCosts,
                                        'Maximum fixed': dirtyFields.costsOverview?.maximumFixed,
                                    };

                                    const newTrackingProps = Object.fromEntries(
                                        Object.entries(trackingProps).filter(([_key, value]) => !!value),
                                    );

                                    Mixpanel.track('Overwriting default values', newTrackingProps);
                                    setShowOverride(false);
                                }}
                            >
                                {intl.formatMessage({ id: 'deals.createOffer.priceOverview.saveCTA' })}
                            </button>
                        </>
                    ) : (
                        <button type="button" className="btn btn-sm  btn-primary" onClick={() => setShowOverride(true)}>
                            {intl.formatMessage({ id: 'deals.createOffer.priceOverview.overwriteCTA' })}
                        </button>
                    )}
                </div>
            </div>
        </fieldset>
    );
};
