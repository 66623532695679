import { useParams } from 'react-router-dom';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { SlideData, SlideTable } from '@trawa-energy/offer-tool-api/appRouter';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { CopyButton } from './CopyButton.tsx';
import { Slide } from '../types.ts';

export function Slides() {
    const { dealId, offerId } = useParams();
    if (!dealId || !offerId) {
        throw new Error("Can't render DealView without dealId");
    }

    const { data } = trpc.deals.getSlides.useQuery({ dealId, offerId });

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedSlide, setSelectedSlide] = useState('');

    const slides = data?.slides.sort((a, b) => b.meta_data.spot_exposure - a.meta_data.spot_exposure);

    return (
        slides && (
            <div>
                <div className="tabs tabs-boxed mb-6">
                    {slides.map((slide, i) => (
                        <a
                            key={i}
                            className={classNames('tab', selectedTab === i && 'tab-active')}
                            onClick={() => setSelectedTab(i)}
                        >
                            Spot {formatIfNumber(slides[i].meta_data.spot_exposure)}
                        </a>
                    ))}
                </div>
                <div className="flex flex-wrap gap-3">
                    {Object.keys(slides[selectedTab].result_data).map((slideName, i) => (
                        <a
                            key={i}
                            className={`cursor-pointer p-2 border-b-2 border-primary ${
                                selectedSlide === slideName && 'bg-primary text-white rounded'
                            }`}
                            onClick={() => setSelectedSlide(slideName)}
                        >
                            Slide "{slideName}"
                        </a>
                    ))}
                </div>
                <div className="space-y-8 pt-6">
                    {Object.entries(slides[selectedTab].result_data).map(
                        ([slideName, value]) =>
                            selectedSlide === slideName && (
                                <div key={slideName} className="space-y-4">
                                    <h2 className="text-xl">Slide "{slideName}"</h2>
                                    <SlideView value={value} />
                                </div>
                            ),
                    )}
                </div>
            </div>
        )
    );
}

function SlideDataView({ value }: { value: SlideData }) {
    const csvContent = useMemo(
        () =>
            Object.entries(value)
                .map(row => row.map(formatIfNumber))
                .map(e => e.join('\t'))
                .join('\n'),
        [value],
    );
    return (
        <table className="table table-zebra table-fixed text-sm">
            <tbody>
                <tr>
                    <td colSpan={2}>
                        <CopyButton value={csvContent} />
                    </td>
                </tr>
                {Object.entries(value).map(([key, value]) => (
                    <tr key={key}>
                        <td className="font-semibold">{key}</td>
                        <td className="">{formatIfNumber(value)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

const toCsv = (data: (string | number)[][]) => {
    const csv = data.map(row => row.map(formatIfNumber));
    return data
        .map(row => row.map(formatIfNumber))
        .map(e => e.join('\t'))
        .join('\n');
};

const numberFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 4 });
const formatIfNumber = (value: string | number) => (typeof value === 'number' ? numberFormatter.format(value) : value);

function SlideTableView({ value }: { value: SlideTable }) {
    const csvContent = useMemo(() => {
        const csv = [...value.data.map((row, y) => [value.index[y], ...row])];
        return csv.map(e => e.join('\t')).join('\n');
    }, [value]);

    return (
        <div className="max-h-[60vh] overflow-auto border border-white">
            <table className="table border table-sm relative">
                <tbody>
                    <tr>
                        <th className="sticky top-[-1px] bg-base-300">
                            <CopyButton value={csvContent} />
                            &nbsp;
                        </th>
                        {value.columns.map(column => (
                            <th key={column} className="text-left sticky top-[-1px] bg-base-300">
                                {column}
                            </th>
                        ))}
                    </tr>
                    {value.data.map((row, y) => (
                        <tr key={value.index[y]}>
                            <td>{value.index[y]}</td>
                            {row.map((cell, i) => (
                                <td key={i}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function SlideView({ value }: { value: Slide }) {
    return (
        <div className="space-y-4">
            {Object.entries(value).map(([key, value]) =>
                key === 'data' ? (
                    <SlideDataView key={key} value={value as SlideData} />
                ) : (
                    <SlideTableView key={key} value={value as SlideTable} />
                ),
            )}
        </div>
    );
}
