import { toast } from 'react-toastify';
import { ReactElement } from 'react';

export const showSuccessToast = (message: string) => {
    toast.success(message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'colored',
    });
};

export const showErrorToast = (message: string | ReactElement) => {
    toast.error(message, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        style: { backgroundColor: '#B0160D', color: '#FAD9D7' },
        theme: 'colored',
    });
};
