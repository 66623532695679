import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

export function getUserSession(user: CognitoUser): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
        user.getSession((error: null | Error, session: null | CognitoUserSession) => {
            if (error) reject(error);
            else resolve(session!);
        });
    });
}
