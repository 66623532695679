import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { RegisterOptions } from 'react-hook-form';

export type DropdownInputProps = {
    id?: string;
    className?: string;
    placeholder?: string;
    defaultValue?: string;
    options?: { value: string; label: string }[];
} & Partial<RegisterOptions> &
    Pick<ComponentProps<'input'>, 'aria-invalid'>;

const DropdownInput = React.forwardRef(({ className, options, ...rest }: DropdownInputProps, ref) => {
    return (
        <select
            type="text"
            ref={ref}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(rest as any)}
            className={classNames('input input-bordered aria-[invalid=true]:input-error w-full max-w-x', className)}
        >
            {options?.map(({ value, label }) => (
                <option key={value} value={value}>
                    {label}
                </option>
            ))}
        </select>
    );
});

export default DropdownInput;
