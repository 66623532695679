import { NavLink, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useFeatureFlags } from '../hooks/useFeatureFlags.tsx';

export function Navigation() {
    const { dealId, ...rest } = useParams();
    const intl = useIntl();
    const { isContractCreationEnabled } = useFeatureFlags();
    return (
        <div className="flex flex-row justify-between gap-4">
            <div role="tablist" className="flex w-full tabs tabs-bordered tabs-md">
                <NavLink
                    role="tab"
                    to={`${dealId}/consumption-analysis`}
                    className={({ isActive }) =>
                        `flex-1 w-full h-full tab ps-1.5 pe-1.5 ${isActive ? 'tab-active' : ''}`
                    }
                >
                    {intl.formatMessage({ id: 'deals.navigation.consumptionAnalysis' })}
                </NavLink>
                <NavLink
                    role="tab"
                    to={`${dealId}/offers`}
                    className={({ isActive }) =>
                        `flex-1 w-full h-full tab ps-1.5 pe-1.5 ${isActive ? 'tab-active' : ''}`
                    }
                >
                    {intl.formatMessage({ id: 'deals.navigation.overview' })}
                </NavLink>
                {isContractCreationEnabled && (
                    <NavLink
                        role="tab"
                        to={`${dealId}/contract-data`}
                        className={({ isActive }) =>
                            `flex-1 w-full h-full tab ps-1.5 pe-1.5 ${isActive ? 'tab-active' : ''}`
                        }
                    >
                        {intl.formatMessage({ id: 'deals.navigation.contractData' })}
                    </NavLink>
                )}
            </div>
        </div>
    );
}
