import { Navigation } from './Navigation.tsx';
import { Outlet, useParams } from 'react-router-dom';
import { CustomConfigProvider } from '../context/CustomConfigProvider.tsx';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import * as Sentry from '@sentry/react';
import { useContext, useEffect } from 'react';
import { AuthContext } from '@trawa-energy/authentication-ui';
import { Mixpanel } from '../../utils/setupMixpanel.ts';

export const OfferLayout = () => {
    const { dealId } = useParams();
    if (!dealId) {
        Sentry.captureException('No dealId provided', { tags: { page: 'Overview' } });
        throw new Error("Can't render DealView without dealId");
    }
    const { data } = trpc.deals.getDeal.useQuery({ dealId }, { enabled: !!dealId });

    const auth = useContext(AuthContext);

    useEffect(() => {
        if (auth?.user) {
            Mixpanel.identify(auth?.user);
        }
    }, [auth]);

    return (
        <CustomConfigProvider>
            <div className="flex justify-around">
                <div className="flex flex-col w-[1000px] mt-16">
                    <h1 className="mb-8">{data?.name}</h1>
                    <Navigation />
                    <Outlet />
                </div>
            </div>
        </CustomConfigProvider>
    );
};
