import { Slide, SlideData, SlideTable } from '../types.ts';
import { useIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import { CopyButton } from './CopyButton.tsx';
import classNames from 'classnames';
import { ArrowDownIcon, ArrowUpIcon } from '@trawa-energy/ui-kit';
import { extractDatefromSlideKey } from '../utils/extractDatefromSlideKey.ts';
import { calculateSumTabeRow } from '../utils/calculateSumTabeRow.ts';

export function SlideView({ value, name }: { value: Slide; name: string }) {
    if (name === 'days_and_year_plot') {
        const allKeys = Object.entries(value);
        const remainingKeys = allKeys.filter(
            ([key, value]) => !key.includes('table_high_wind_day') && !key.includes('table_low_wind_day'),
        );
        const prioritizedKeys = allKeys.filter(
            ([key, value]) => key.includes('table_high_wind_day') || key.includes('table_low_wind_day'),
        );
        const newOrder: [string, SlideTable][] = [
            ...(prioritizedKeys as [string, SlideTable][]),
            ...(remainingKeys as [string, SlideTable][]),
        ];

        return (
            <div className="space-y-4">
                {newOrder.map(([key, value]) => (
                    <SlideTableView
                        key={key}
                        value={value}
                        name={name}
                        tableKey={key}
                        isCollapsed={!(key.includes('table_high_wind_day') || key.includes('table_low_wind_day'))}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className="space-y-4">
            {Object.entries(value).map(([key, value]) =>
                key === 'data' ? (
                    <SlideDataView
                        key={key}
                        value={value as SlideData}
                        name={name}
                        withTranslation={name !== 'kpi_for_approval'}
                    />
                ) : (
                    <SlideTableView key={key} value={value as SlideTable} name={name} />
                ),
            )}
        </div>
    );
}

function SlideDataView({
    value,
    name,
    withTranslation = true,
}: {
    value: SlideData;
    name: string;
    withTranslation?: boolean;
}) {
    const intl = useIntl();
    const csvContent = useMemo(
        () =>
            Object.entries(value)
                .map(row => row.map(formatIfNumber))
                .map(e => e.join('\t'))
                .join('\n'),
        [value],
    );

    let pattern: number[] = [];
    if (name === 'composition_of_contract') {
        const createPattern = () => {
            const rows = Array.from(Array(Object.keys(value).length).keys());
            const result = [];

            const NUM_OF_KEYS = 6;
            for (let i = 0; i < rows.length; i += NUM_OF_KEYS * 2) {
                result.push(...rows.slice(i, i + NUM_OF_KEYS));
            }
            return result;
        };
        pattern = createPattern();
    }

    return (
        <>
            <div className="my-4">
                <CopyButton value={csvContent} />
            </div>
            <table className="table">
                <tbody>
                    {Object.entries(value).map(([key, value], index) => (
                        <tr className={classNames({ 'bg-base-200': pattern.includes(index) })} key={key}>
                            <td className="font-semibold">
                                {withTranslation
                                    ? intl.formatMessage({ id: `deals.optionsOutput.${name}.${key}` })
                                    : key}
                            </td>
                            <td>
                                {key === 'profile_fit' && typeof value === 'string'
                                    ? intl.formatMessage({
                                          id: `deals.optionsOutput.load_analysis.profile_fit_${value.toLowerCase()}`,
                                      })
                                    : withTranslation
                                      ? intl.formatMessage(
                                            { id: `deals.optionsOutput.${name}.${key}_value` },
                                            { value: formatIfNumber(value) },
                                        )
                                      : value}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

const numberFormatter = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 4 });
const formatIfNumber = (value: string | number) => (typeof value === 'number' ? numberFormatter.format(value) : value);

function SlideTableView({
    value,
    name,
    isCollapsed,
    tableKey,
}: {
    value: SlideTable;
    name: string;
    tableKey?: string;
    isCollapsed?: boolean;
}) {
    const intl = useIntl();
    const [isHidden, setIsHidden] = useState(isCollapsed);

    const csvContent = useMemo(() => {
        const csv = [...value.data.map((row, y) => [value.index[y], ...row])];
        return csv.map(e => e.join('\t')).join('\n');
    }, [value]);

    const { title, date, day } = extractDatefromSlideKey(tableKey);

    const summaryRow = useMemo(() => calculateSumTabeRow(value), [value]);

    return (
        <div>
            {name === 'days_and_year_plot' && (
                <h3 className="my-4">
                    {intl.formatMessage(
                        {
                            id: `deals.optionsOutput.${name}.${title}`,
                            defaultMessage: 'Table title missing',
                        },
                        { number: day },
                    )}
                </h3>
            )}
            {date && <h4>{intl.formatDate(date, { day: 'numeric', month: 'long' })}</h4>}
            <div className="max-h-[60vh] overflow-auto border border-white">
                <table className="table border table-sm relative">
                    <tbody>
                        <tr>
                            <th className="sticky top-[-1px] bg-base-300">
                                <CopyButton value={csvContent} />
                            </th>
                            {value.columns.map(column => (
                                <th key={column} className="text-left sticky top-[-1px] bg-base-300">
                                    {intl.formatMessage({ id: `deals.optionsOutput.${name}.${column}` })}
                                </th>
                            ))}
                            {isCollapsed && (
                                <th className="sticky top-[-1px] bg-base-300">
                                    {isHidden ? (
                                        <button
                                            onClick={() => setIsHidden(!isHidden)}
                                            className="sticky btn bg-base-300 border-base-300 collapse-arrow"
                                        >
                                            <ArrowDownIcon />
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => setIsHidden(!isHidden)}
                                            className="btn bg-base-300 border-base-300 collapse-arrow"
                                        >
                                            <ArrowUpIcon />
                                        </button>
                                    )}
                                </th>
                            )}
                        </tr>

                        {value.data.map((row, y) => (
                            <tr className={classNames({ hidden: isHidden })} key={value.index[y]}>
                                <td>{value.index[y]}</td>
                                {row.map((cell, i) => (
                                    <td key={i}>{cell}</td>
                                ))}
                            </tr>
                        ))}
                        <tr className={classNames({ hidden: !isHidden })} key={'summaryRow'}>
                            {summaryRow.map((cell, i) => (
                                <td key={i}>{cell}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
