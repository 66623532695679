import { useParams } from 'react-router-dom';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import { useIntl } from 'react-intl';

export function ViewConfig() {
    const { dealId, offerId } = useParams();
    const [isV2, setIsV2] = useState(true);
    const intl = useIntl();
    if (!dealId || !offerId) {
        throw new Error("Can't render ViewConfig without dealId and offerId");
    }
    const { data, isLoading, isError } = trpc.deals.getOfferRawCustomerConfig.useQuery(
        {
            dealId,
            offerId,
        },
        { enabled: !!dealId && !!offerId },
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error...</div>;
    }

    return (
        <div className="my-12">
            <Editor
                width="100%"
                height="70vh"
                options={{ readOnly: true }}
                defaultLanguage={'yaml'}
                defaultValue={data ?? ''}
            />
        </div>
    );
}
