import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { trpcBackoffice as trpc } from '../utils/trpc';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Field } from '../shared/field/Field';
import TextInput from '../shared/inputs/TextInput';
// import { UserForm } from './UserForm';
import classNames from 'classnames';
import { UserForm } from './UserForm';
import DropdownInput from '../shared/inputs/DropdownInput';

const accountTypes = ['RealEstate', 'Industrial'] as const;

const accountFormValuesSchema = z.object({
    name: z.string().min(1),
    type: z.enum(accountTypes),
});
const resolver = zodResolver(accountFormValuesSchema);

export type AccountFormValues = z.infer<typeof accountFormValuesSchema>;

const accountTypeOptions = [{ label: '', value: '' }, ...accountTypes.map(type => ({ value: type, label: type }))];

export function AccountForm({
    accountId,
    className,
    onSaved,
}: {
    accountId?: string;
    className?: string;
    onSaved?: () => void;
}) {
    const navigate = useNavigate();
    const { mutateAsync } = trpc.accounts.save.useMutation();

    const { mutateAsync: deleteAsync } = trpc.accounts.deleteById.useMutation();

    const accountExists = !!accountId;
    const [serverError, setServerError] = useState<string>();

    const { data: account } = trpc.accounts.getById.useQuery({ id: accountId! }, { enabled: !!accountId });
    const [addUserDialog, setAddUserDialog] = useState<HTMLDialogElement | null>(null);
    const methods = useForm<{ name: string; type: string }, unknown, AccountFormValues>({
        resolver,
        defaultValues: {
            name: '',
            type: '',
        },
    });

    useEffect(() => {
        if (account) {
            methods.reset(account);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!account]);

    const onSubmit = async (data: AccountFormValues) => {
        try {
            await mutateAsync({ ...data, featureFlags: account?.featureFlags ?? [], id: accountId });
            onSaved?.();
        } catch (error) {
            if (error instanceof Error) {
                setServerError(error.message);
            } else {
                console.error(error);
                setServerError('Unknown error');
            }
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className={classNames(className, 'space-y-5')}>
                    <h3 className="text-lg">{accountId ? 'Edit account' : 'Create account'}</h3>
                    <fieldset className="space-y-4">
                        <Field component={TextInput} required name="name" label="Name" />
                        <Field
                            component={DropdownInput}
                            required
                            options={accountTypeOptions}
                            name="type"
                            label="Type"
                        />
                    </fieldset>

                    {serverError && <div className="alert alert-error">{serverError}</div>}
                    <div className="space-x-4">
                        <button className="btn btn-primary" type="submit">
                            Save
                        </button>
                        {account && (
                            <button
                                className="btn btn-error"
                                onClick={async () => {
                                    if (prompt(`Write "${account.name}" to confirm deletion`) === account.name) {
                                        await deleteAsync({ id: account.id });
                                        navigate(`/accounts`);
                                    }
                                }}
                            >
                                Delete
                            </button>
                        )}
                        {accountExists && (
                            <button
                                className="btn btn-primary btn-outline"
                                type="button"
                                onClick={() => addUserDialog?.showModal()}
                            >
                                Add user
                            </button>
                        )}
                    </div>
                </form>
            </FormProvider>
            {accountExists && (
                <dialog ref={setAddUserDialog} className="modal">
                    <div className="modal-box">
                        <button
                            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                            onClick={() => addUserDialog?.close()}
                        >
                            ✕
                        </button>
                        <UserForm accountId={accountId} onSaved={() => addUserDialog?.close()} />
                    </div>
                </dialog>
            )}
        </>
    );
}
