import { useCallback, useEffect } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

export const usePrompt = (when: boolean) => {
    const blocker = useBlocker(when);
    useEffect(() => {
        if (blocker.state === 'blocked' && !when) {
            blocker.reset();
        }
    }, [blocker, when]);

    useBeforeUnload(
        useCallback(
            event => {
                if (when) {
                    event.preventDefault();
                    event.returnValue = 'Changes that you made may not be saved.'; // TODO: have to check if really necessary
                }
            },
            [when],
        ),
        { capture: true },
    );

    return blocker;
};
