import { useIntl } from 'react-intl';
import { useEffect, useRef } from 'react';

export const DeleteOfferModal = ({
    onClose,
    onConfirm,
    isOpen,
    isPendingDelete,
}: {
    onClose: () => void;
    onConfirm: () => Promise<void>;
    isOpen: boolean;
    isPendingDelete: boolean;
}) => {
    const intl = useIntl();
    const dialogRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (isOpen) {
            dialogRef.current?.showModal();
        } else {
            dialogRef.current?.close();
        }
    }, [isOpen]);

    return (
        <dialog ref={dialogRef} className="modal">
            <div className="modal-box">
                <form method="dialog">
                    <button onClick={onClose} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                    </button>
                </form>
                <h3 className="font-bold text-lg">
                    {intl.formatMessage({ id: 'deals.overview.modalDeletePrompt.title' })}
                </h3>
                <p className="py-4">{intl.formatMessage({ id: 'deals.overview.modalDeletePrompt.message' })}</p>
                <form method="dialog" className="modal-backdrop flex gap-4">
                    <button
                        disabled={isPendingDelete}
                        className="btn btn-md"
                        onClick={() => {
                            dialogRef.current?.close();
                            onClose();
                        }}
                    >
                        {intl.formatMessage({ id: 'deals.overview.modalDeletePrompt.cancel' })}
                    </button>
                    <button
                        disabled={isPendingDelete}
                        className="btn btn-md"
                        onClick={async event => {
                            event.preventDefault();
                            await onConfirm();
                            dialogRef.current?.close();
                            onClose();
                        }}
                    >
                        {isPendingDelete && <span className="loading loading-spinner"></span>}
                        {intl.formatMessage({ id: 'deals.overview.modalDeletePrompt.confirm' })}
                    </button>
                </form>
            </div>
        </dialog>
    );
};
