import { useEffect } from 'react';
import { useElementsObserver } from './use-elements-observer';

export function useAutoLogin({ credentials }: { credentials: { username: string; password: string } | null }) {
    const amplifyLoginElements = useElementsObserver({
        skip: !credentials,
        container: document,
        elementSelectors: [
            'form[data-amplify-form] input[name="username"]',
            'form[data-amplify-form] input[name="password"]',
            'form[data-amplify-form] [type="submit"]',
        ],
    });

    useEffect(() => {
        if (credentials && amplifyLoginElements) {
            let usernameInput = amplifyLoginElements.find(
                x => x.getAttribute('name') === 'username',
            ) as unknown as HTMLInputElement;
            usernameInput.value = credentials.username;
            let passwordInput = amplifyLoginElements.find(
                x => x.getAttribute('name') === 'password',
            ) as unknown as HTMLInputElement;
            passwordInput.value = credentials.password;
            let submit = amplifyLoginElements.find(x => x.getAttribute('type') === 'submit') as unknown as HTMLElement;
            submit.click();
        }
    }, [credentials, amplifyLoginElements]);
}
