import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { LockLockedIcon, PenIcon } from '@trawa-energy/ui-kit';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { TextInputField } from '../components/TextInputField.tsx';
import { ErrorBanner } from '../components/ErrorBanner.tsx';

type FormValues = {
    companyAddress: {
        street: string;
        houseNumber: string;
        postCode: string;
        city: string;
    };
    contact: {
        firstName: string;
        lastName: string;
        email: string;
        position: string;
    };
    companyRegistation: {
        number: string;
        location: string;
    };
};
export const ContractData = () => {
    const { dealId } = useParams();

    const intl = useIntl();
    if (!dealId) {
        throw new Error("Can't render DealView without dealId");
    }

    const [isEditable, setIsEditable] = useState(false);
    const { data: deal } = trpc.deals.getDeal.useQuery({ dealId }, { enabled: !!dealId });
    const { data: dealData, refetch: refetchContractData } = trpc.deals.getDealDetails.useQuery(
        { dealId },
        { enabled: !!dealId },
    );
    const {
        mutateAsync: updateContractData,
        isPending: isPendingUpdate,
        isError: isUpdateError,
    } = trpc.deals.updateDeal.useMutation({
        onSuccess: refetchContractData,
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<FormValues>({
        defaultValues: {
            companyAddress: {
                street: '',
                houseNumber: '',
                postCode: '',
                city: '',
            },
            contact: {
                firstName: '',
                lastName: '',
                email: '',
                position: '',
            },
            companyRegistation: {
                number: '',
                location: '',
            },
        },
        mode: 'onChange',
    });

    useEffect(() => {
        if (dealData) {
            const { hubspotDealId, id, name, createdByHubSpotUserId, ...contractData } = dealData;
            const hasAdditionalAttributes = Object.keys(contractData).length > 0;
            if (hasAdditionalAttributes) {
                setIsEditable(false);
                setValue('companyAddress.street', contractData?.addressStreet ?? '');
                setValue('companyAddress.houseNumber', contractData?.addressHouseNumber ?? '');
                setValue('companyAddress.postCode', contractData?.addressPostalCode ?? '');
                setValue('companyAddress.city', contractData?.addressCity ?? '');
                setValue('contact.firstName', contractData?.contactFirstName ?? '');
                setValue('contact.lastName', contractData?.contactLastName ?? '');
                setValue('contact.email', contractData?.contactEmailAddress ?? '');
                setValue('contact.position', contractData?.contactJobTitle ?? '');
                setValue('companyRegistation.number', contractData?.companyRegistrationNumber ?? '');
                setValue('companyRegistation.location', contractData?.companyPlaceOfRegistration ?? '');
            } else {
                setIsEditable(true);
            }
        }
    }, [dealData, setIsEditable, setValue]);

    const submitData = useCallback(
        async (values: FormValues) => {
            setIsEditable(false);

            try {
                await updateContractData({
                    dealId,
                    addressStreet: values.companyAddress.street,
                    addressHouseNumber: values.companyAddress.houseNumber,
                    addressPostalCode: values.companyAddress.postCode,
                    addressCity: values.companyAddress.city,
                    contactFirstName: values.contact.firstName,
                    contactLastName: values.contact.lastName,
                    contactEmailAddress: values.contact.email,
                    contactJobTitle: values.contact.position,
                    companyRegistrationNumber: values.companyRegistation.number,
                    companyPlaceOfRegistration: values.companyRegistation.location,
                });
            } catch (e) {
                setIsEditable(true);
            }
        },
        [setIsEditable, updateContractData, dealId],
    );

    return (
        <div>
            <div className="my-8">
                <h2>{intl.formatMessage({ id: 'deals.contractData.subHeading' })}</h2>
                <form onSubmit={handleSubmit(submitData)}>
                    <fieldset className="my-8">
                        <h3 className="mb-4">
                            {intl.formatMessage(
                                { id: 'deals.contractData.companyAddress.title' },
                                { companyName: deal?.name },
                            )}
                        </h3>
                        <div className="flex gap-8">
                            <TextInputField
                                label={intl.formatMessage({ id: 'deals.contractData.companyAddress.street.label' })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.companyAddress.street.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.companyAddress?.street}
                                bottomLeftErrorLabel={errors.companyAddress?.street?.message}
                                {...register('companyAddress.street', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.companyAddress.street.error.required',
                                    }),
                                })}
                            />
                            <TextInputField
                                label={intl.formatMessage({
                                    id: 'deals.contractData.companyAddress.houseNumber.label',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.companyAddress.houseNumber.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.companyAddress?.houseNumber}
                                bottomLeftErrorLabel={errors.companyAddress?.houseNumber?.message}
                                {...register('companyAddress.houseNumber', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.companyAddress.houseNumber.error.required',
                                    }),
                                })}
                            />
                        </div>
                        <div className="flex gap-8">
                            <TextInputField
                                label={intl.formatMessage({ id: 'deals.contractData.companyAddress.postalCode.label' })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.companyAddress.postalCode.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.companyAddress?.postCode}
                                bottomLeftErrorLabel={errors.companyAddress?.postCode?.message}
                                {...register('companyAddress.postCode', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.companyAddress.postalCode.error.required',
                                    }),
                                    pattern: {
                                        value: /^\d{5}$/,
                                        message: intl.formatMessage({
                                            id: 'deals.contractData.companyAddress.postalCode.error.pattern',
                                        }),
                                    },
                                })}
                            />
                            <TextInputField
                                label={intl.formatMessage({ id: 'deals.contractData.companyAddress.city.label' })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.companyAddress.city.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.companyAddress?.city}
                                bottomLeftErrorLabel={errors.companyAddress?.city?.message}
                                {...register('companyAddress.city', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.companyAddress.city.error.required',
                                    }),
                                })}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="my-8">
                        <h3 className="mb-4">{intl.formatMessage({ id: 'deals.contractData.contact.title' })}</h3>
                        <div className="flex gap-8">
                            <TextInputField
                                label={intl.formatMessage({ id: 'deals.contractData.contact.firstName.label' })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.contact.firstName.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.contact?.firstName}
                                bottomLeftErrorLabel={errors.contact?.firstName?.message}
                                {...register('contact.firstName', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.contact.firstName.error.required',
                                    }),
                                })}
                            />
                            <TextInputField
                                label={intl.formatMessage({
                                    id: 'deals.contractData.contact.lastName.label',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.contact.lastName.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.contact?.lastName}
                                bottomLeftErrorLabel={errors.contact?.lastName?.message}
                                {...register('contact.lastName', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.contact.lastName.error.required',
                                    }),
                                })}
                            />
                        </div>
                        <div className="flex gap-8">
                            <TextInputField
                                label={intl.formatMessage({ id: 'deals.contractData.contact.email.label' })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.contact.email.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.contact?.email}
                                bottomLeftErrorLabel={errors.contact?.email?.message}
                                {...register('contact.email', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.contact.email.error.required',
                                    }),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: intl.formatMessage({
                                            id: 'deals.contractData.contact.email.error.pattern',
                                        }),
                                    },
                                })}
                            />
                        </div>
                        <div className="flex gap-8">
                            <TextInputField
                                label={intl.formatMessage({ id: 'deals.contractData.contact.position.label' })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.contact.position.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.contact?.position}
                                {...register('contact.position')}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="my-8">
                        <h3 className="mb-4">
                            {intl.formatMessage({ id: 'deals.contractData.companyRegistration.title' })}
                        </h3>
                        <div className="flex gap-8">
                            <TextInputField
                                label={intl.formatMessage({
                                    id: 'deals.contractData.companyRegistration.companyNumber.label',
                                })}
                                disabled={!isEditable}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.companyRegistration.companyNumber.placeholder',
                                })}
                                hasError={!!errors.companyRegistation?.number}
                                bottomLeftErrorLabel={errors.companyRegistation?.number?.message}
                                {...register('companyRegistation.number', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.companyRegistration.companyNumber.error.required',
                                    }),
                                })}
                            />
                            <TextInputField
                                label={intl.formatMessage({
                                    id: 'deals.contractData.companyRegistration.companyLocation.label',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'deals.contractData.companyRegistration.companyLocation.placeholder',
                                })}
                                disabled={!isEditable}
                                hasError={!!errors.companyRegistation?.location}
                                bottomLeftErrorLabel={errors.companyRegistation?.location?.message}
                                {...register('companyRegistation.location', {
                                    required: intl.formatMessage({
                                        id: 'deals.contractData.companyRegistration.companyLocation.error.required',
                                    }),
                                })}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="my-8">
                        <ErrorBanner>
                            {isUpdateError && <p>{intl.formatMessage({ id: 'deals.contractData.errors.update' })}</p>}
                        </ErrorBanner>
                    </fieldset>
                    <fieldset className="my-8">
                        {!isEditable && !isPendingUpdate ? (
                            <button
                                onClick={e => {
                                    e.preventDefault();
                                    setIsEditable(true);
                                }}
                                className="btn btn-primary flex items-center"
                            >
                                {intl.formatMessage({ id: 'deals.contractData.CTA.edit' })}
                                <PenIcon width={20} height={20} />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className={classNames('btn btn-primary', { 'btn-disabled': isPendingUpdate })}
                                disabled={isPendingUpdate}
                            >
                                {intl.formatMessage({ id: 'deals.contractData.CTA.save' })}
                                <LockLockedIcon />
                            </button>
                        )}
                    </fieldset>
                </form>
            </div>
        </div>
    );
};
