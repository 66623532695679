import { Auth } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';

export function useElementsObserver({
    container,
    skip,
    elementSelectors,
}: {
    container: Node;
    skip: boolean;
    elementSelectors: string[];
}) {
    const observerRef = useRef<MutationObserver | null>(null);
    const [elements, setElements] = useState<HTMLElement[]>([]);

    useEffect(() => {
        const checkElements = () => {
            const foundElements = elementSelectors
                .map(selector => document.querySelector(selector))
                .filter((x): x is HTMLElement => x != null);
            console.log('found', foundElements);
            setElements(foundElements);

            if (foundElements.length === elementSelectors.length) {
                console.log('disconnecting');
                observerRef.current?.disconnect();
            }
        };

        if (skip) return;
        Auth.currentAuthenticatedUser()
            .then(() => {
                console.log('No auto login to change password: already logged in');
            })
            .catch(() => {
                // User is not logged in. So logging in
                observerRef.current = new MutationObserver(() => checkElements());

                observerRef.current.observe(document, {
                    childList: true,
                    subtree: true,
                });
            });

        return () => {
            observerRef.current?.disconnect();
        };
    }, [setElements, ...elementSelectors]);

    return elements.length === elementSelectors.length ? elements : null;
}
