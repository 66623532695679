import { createBrowserRouter, Navigate, Outlet, useParams } from 'react-router-dom';
import Root from './Root';
import { Accounts } from './users/Accounts';
import { AccountForm } from './users/AccountForm';
import { Slides } from './deals/components/Slides';
import { config } from './config';
import { Users } from './users/Users';
import { UserForm } from './users/UserForm';
import { OfferLayout } from './deals/components/OfferLayout.tsx';
import { Overview } from './deals/pages/Overview.tsx';
import { ConsumptionAnalysis } from './deals/pages/ConsumptionAnalysis.tsx';
import { Summary } from './deals/pages/Summary.tsx';
import { CreateOffer } from './deals/pages/CreateOffer.tsx';
import { OptionsOverview } from './deals/pages/OptionsOverview.tsx';
import { ViewConfig } from './deals/pages/ViewConfig.tsx';
import { AuthTRpcProvidersOfferTool } from './AuthTRpcProviders.tsx';
import { ContractData } from './deals/pages/ContractData.tsx';

/* TODO: find a better way to have feature flags */
const isV2 = true;

// eslint-disable-next-line react-refresh/only-export-components
const UserView = () => <UserForm userId={useParams().userId} />;

// eslint-disable-next-line react-refresh/only-export-components
const AccountView = () => <AccountForm accountId={useParams().accountId} />;

const isInIFrame = window.self !== window.top;

// eslint-disable-next-line react-refresh/only-export-components
const ContentOnlyRoot = () => (
    <div className="m-8">
        <Outlet />
    </div>
);

export const router = createBrowserRouter([
    {
        path: '/',
        element: window.localStorage.getItem('fullUI') === 'true' ? <Root /> : <ContentOnlyRoot />, //isInIFrame ? <ContentOnlyRoot /> : <Root />,
        errorElement: <>error!</>,
        children: [
            {
                path: '',
                element: <Navigate to="/accounts" replace />,
            },
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        element: <Users />,
                    },
                    {
                        path: ':userId',
                        element: <UserView />,
                    },
                ],
            },
            {
                path: 'accounts',
                children: [
                    {
                        path: '',
                        element: <Accounts />,
                    },
                    {
                        path: ':accountId',
                        element: <AccountView />,
                    },
                ],
            },
            {
                path: '/deals',
                element: (
                    <AuthTRpcProvidersOfferTool>
                        <OfferLayout />
                    </AuthTRpcProvidersOfferTool>
                ),
                errorElement: <>error!</>,
                children: [
                    {
                        path: '',
                        element: (
                            <div className="my-8">
                                <a className="btn btn-link btn-neutral" href={config.hubspotDealAppInstallerUrl}>
                                    Deal App installer
                                </a>
                            </div>
                        ),
                    },
                    {
                        path: ':dealId',
                        element: <Navigate to="offers" replace />,
                    },
                    {
                        path: ':dealId/consumption-analysis',
                        element: <ConsumptionAnalysis />,
                    },
                    {
                        path: ':dealId/offers',
                        element: <Overview />,
                    },

                    {
                        path: ':dealId/offers/create',
                        element: <CreateOffer />,
                    },
                    {
                        path: ':dealId/offers/:offerId/viewConfig',
                        element: <ViewConfig />,
                    },
                    {
                        path: ':dealId/offers/:offerId/summary',
                        element: <Summary />,
                    },
                    {
                        path: ':dealId/offers/:offerId/options/:optionCategory',
                        element: <OptionsOverview />,
                    },
                    {
                        path: ':dealId/calculations/:offerId/slides',
                        element: <Slides />,
                    },
                    {
                        path: ':dealId/contract-data',
                        element: <ContractData />,
                    },
                ],
            },
        ],
    },
]);
