import { useState } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useIntl } from 'react-intl';

export function CopyButton({ value }: { value: string }) {
    const intl = useIntl();
    const [copied, setCopied] = useState(false);

    const handleCopy = (content: string): boolean => {
        const textarea = document.createElement('textarea');
        textarea.textContent = content;
        document.body.appendChild(textarea);
        textarea.select();
        let isCopied = false;
        try {
            isCopied = document.execCommand('copy');
        } catch (err) {
            console.error('Unable to copy', err);
        } finally {
            document.body.removeChild(textarea);
        }
        return isCopied;
    };

    return (
        <button
            className={classNames('btn btn-sm transition-all duration-500', copied ? 'btn-success' : 'btn-neutral')}
            onClick={async () => {
                /* TODO check with hubspot if Clipboard API can be supported */
                const isCopied = handleCopy(value);
                isCopied && setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            }}
        >
            {intl.formatMessage({ id: 'deals.optionsOutput.csvButton' })}
            {copied && <CheckIcon className="text-black inline-block h-4" />}
        </button>
    );
}
