import * as React from 'react';
import Downshift from 'downshift';
import { useIntl } from 'react-intl';
import { ChevronDownIcon, ChevronUpIcon } from '@trawa-energy/ui-kit';
import classNames from 'classnames';

export const Dropdown = ({
    items,
    onSelect,
    isGeneratingPresentation,
}: {
    items: { value: string; id: string }[];
    onSelect: (selectedItem: { value: string } | null) => Promise<void>;
    isGeneratingPresentation: boolean;
}) => {
    const intl = useIntl();

    return (
        <Downshift onSelect={onSelect} itemToString={item => (item ? item.value : '')}>
            {({ getToggleButtonProps, getItemProps, getMenuProps, isOpen, highlightedIndex }) => (
                <div className="dropdown dropdown-end my-4">
                    <button {...getToggleButtonProps()} className="dropdown-toggle flex items-center">
                        {intl.formatMessage({ id: 'deals.summary.options.results.tableBody.slideDecks' })}
                        <span>
                            {isOpen ? (
                                <ChevronUpIcon className="w-6 h-6 ml-2" />
                            ) : (
                                <ChevronDownIcon className="w-6 h-6 ml-2" />
                            )}
                        </span>
                    </button>
                    <ul
                        {...getMenuProps()}
                        className={`border border-black dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 text-left ${
                            !isOpen ? 'hidden' : ''
                        }`}
                    >
                        {isOpen &&
                            items.map((item, index) => (
                                <li
                                    key={item.id}
                                    {...getItemProps({
                                        index,
                                        item,
                                        className: classNames('p-2', {
                                            'bg-blue-600 text-white rounded-lg': highlightedIndex === index,
                                            disabled: isGeneratingPresentation,
                                        }),
                                        disabled: isGeneratingPresentation,
                                    })}
                                >
                                    {item.value}
                                </li>
                            ))}
                    </ul>
                </div>
            )}
        </Downshift>
    );
};
