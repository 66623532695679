import { Link, Outlet, useMatch } from 'react-router-dom';

export default function Root() {
    return (
        <div className="bg-base-100 drawer lg:drawer-open">
            <input id="drawer" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content">
                <NavBar />
                <div className="max-w-4xl p-6 xl:pr-2">
                    <Outlet />
                </div>
            </div>
            <div className="drawer-side z-40">
                <label htmlFor="drawer" className="drawer-overlay"></label>
                <ul className="menu menu-lg p-4 w-80 h-full bg-base-200 text-base-content">
                    <li>
                        <Link to="/accounts">Accounts</Link>
                    </li>
                    <li>
                        <Link to="/users">Users</Link>
                    </li>
                    <li>
                        <Link to="/deals">Deals</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

function NavBar() {
    return (
        <div className="sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-90 backdrop-blur transition-all duration-100 bg-base-100 text-base-content shadow-sm">
            <div className="navbar w-full">
                <div className="flex-none">
                    <label htmlFor="drawer" className="btn btn-square btn-ghost drawer-button lg:hidden">
                        <svg
                            width="20"
                            height="20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            className="inline-block h-5 w-5 stroke-current md:h-6 md:w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </label>
                </div>
                <div className="flex-1">
                    <span className="p-4 text-xl">Backoffice</span>
                </div>
            </div>
        </div>
    );
}
