import { CustomerConfig } from '@trawa-energy/offer-tool-api/deals/schemas';
import { FormValues } from '../pages/CreateOffer.tsx';
import { Temporal } from 'temporal-polyfill';
import { convertCentToEuro } from './precisionUtils.ts';
import { convertToKwh } from './convertMaxima.ts';

export const transformFormValuesToConfig = ({
    values,
    config,
    yearlyConsumption,
}: {
    values: FormValues;
    config: CustomerConfig;
    yearlyConsumption: number;
}): CustomerConfig => {
    const convertedProductsNumber = Object.fromEntries(
        Object.entries(values.products.number).map(([key, value]) => {
            const number = Number(value);
            const kwh = values.products.isMaximaInKwh === 'true' ? number : convertToKwh(number, yearlyConsumption);
            return [key, kwh === yearlyConsumption ? null : kwh];
        }),
    );

    return {
        ...config,
        offer_name: values.general.offerName,
        contract_settings: {
            ...config.contract_settings,
            start_date_year: Temporal.PlainDate.from(values.general.startDate).year,
            duration_years: values.general.duration,
            is_external_offer: values.general.hasIncludedProvision,
        },
        core_opt_settings: {
            ...config.core_opt_settings,
            maximum_fixed: Number(values.costsOverview.maximumFixed),
        },
        prices: {
            ...config.prices,
            trawa_internal_fee: convertCentToEuro(values.costsOverview.trawaInternalFee!),
            distribution_fee:
                values.general.hasIncludedProvision && values.costsOverview.distributionFee
                    ? convertCentToEuro(values.costsOverview.distributionFee)
                    : 0,
            goo_price: convertCentToEuro(values.costsOverview.gooPrice),
            monthly_base_fee:
                config.contract_settings.sector === 'real-estate' &&
                !!Number(config.contract_settings.number_of_market_locations)
                    ? Number(values.costsOverview.meterCosts!)
                    : 0,
            ppa_price_wind_raw: convertCentToEuro(values.costsOverview!.ppaPriceWind!),
            ppa_price_solar_raw: convertCentToEuro(values.costsOverview.ppaPriceSolar!),
            ppa_trade_margin: convertCentToEuro(values.costsOverview.tradeMarginFee!),
            price_peak_years: values.costsOverview.eexPrices.peakEexPrices,
            price_base_years: values.costsOverview.eexPrices.baseEexPrices,
        },
        opt_settings: {
            single_offer_mode: {
                activate: values.costsOverview.isSingleOfferMode,
                spot_exposure_single_offer_mode: Number(values.costsOverview.spotExposureSingleOffer),
            },
            maxima: {
                wind: convertedProductsNumber.wind,
                solar: convertedProductsNumber.solar,
                base: convertedProductsNumber.base,
                peak: convertedProductsNumber.peak,
                offpeak: convertedProductsNumber.offpeak,
            },
        },
    };
};
