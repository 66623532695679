import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { getUserSession } from './getUserSession';
import { AuthContext } from './authContext';
import './Authenticated.scss';
import { Amplify } from 'aws-amplify';
import { useAutoLogin } from './use-auto-login';

// NOTE:
// amplify-ui is in a bad shape with substantial technical debt and broken types unaddressed. But it's very easy to use.
// The intention of this wrapper package is to avoid leaking amplify-ui types, and maybe eventually replace amplify-ui.

type AuthParams = {
    aws_cognito_region: string;
    aws_user_pools_id: string;
    aws_user_pools_web_client_id: string;
    aws_mandatory_sign_in: string;
};

export const changePasswordPath = 'change-password';

export const AuthenticatedWithCognito: FC<PropsWithChildren<AuthParams>> = ({ children, ...authParams }) => {
    useEffect(() => {
        Amplify.configure(authParams);
    }, []);

    const [changePasswordCredentials] = useState(() => {
        const url = new URL(window.location.href);

        const isChangePassword = url.pathname.endsWith('/' + changePasswordPath);
        const username = url.searchParams.get('username');
        const password = url.searchParams.get('password');

        return isChangePassword && username && password
            ? {
                  username,
                  password,
              }
            : null;
    });

    useAutoLogin({ credentials: changePasswordCredentials });
    return (
        <div className="auth-ui-root">
            <Authenticator
                className="vh-100"
                formFields={{
                    signIn: {
                        username: {
                            placeholder: 'Enter Your Email',
                            isRequired: true,
                            label: 'Email',
                        },
                    },
                    signUp: {
                        username: {
                            placeholder: 'Enter Your Email',
                            isRequired: true,
                            label: 'Email',
                        },
                    },
                }}
                hideSignUp
            >
                <AuthenticatedWrapper>
                    <div className="auth-authenticated-container">{children}</div>
                </AuthenticatedWrapper>
            </Authenticator>
        </div>
    );
};

function AuthenticatedWrapper({ children }: { children: React.ReactNode }) {
    const { signOut, user } = useAuthenticator((context: any) => [context.route, context.signOut, context.user]); // context.route here is due to a bug that was still happening in 2022
    const authContextValue = useMemo(
        () => ({
            signOut,
            getAccessToken: () => getUserSession(user).then(s => s.getAccessToken().getJwtToken()),
            user: user.username,
        }),
        [user, signOut],
    );
    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}
