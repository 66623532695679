import React, { PropsWithChildren, ReactNode } from 'react';
import { Label } from './Label';
import classNames from 'classnames';
import { useFormContext, useFormState } from 'react-hook-form';

type MinimumInputProps = {
    id?: string;
    ['aria-invalid']?: React.ComponentProps<'input'>['aria-invalid'];
};

type ExplicitWrapperProps = PropsWithChildren<{
    name: string;
    label?: ReactNode;
    wrapperClassName?: string;
    inline?: boolean;
    required?: boolean;
}>;

type FieldProps<InnerProps> = {
    component: React.ComponentType<InnerProps>;
} & ExplicitWrapperProps &
    InnerProps;

export function Field<InnerProps extends MinimumInputProps>({
    name,
    label,
    wrapperClassName,
    inline,
    required,
    component: Component,
    ...otherInputProps
}: FieldProps<InnerProps>) {
    const { register } = useFormContext();
    const { errors } = useFormState({ name });
    const field = register(name);
    const errorMessage = errors[name]?.message;
    const id = name;
    return (
        <FieldWrapper
            id={id}
            label={label}
            required={required}
            wrapperClassName={wrapperClassName}
            inline={inline}
            name={name}
            errorMessage={typeof errorMessage === 'string' ? errorMessage : undefined}
        >
            <Component
                id={id}
                aria-invalid={errorMessage ? true : undefined}
                {...(otherInputProps as unknown as InnerProps)}
                {...field}
            ></Component>
        </FieldWrapper>
    );
}

type DerivedWrapperProps = {
    id: string;
    errorMessage: string | undefined;
};

export function FieldWrapper({
    wrapperClassName,
    inline,
    required,
    label,
    children,
    errorMessage,
}: ExplicitWrapperProps & DerivedWrapperProps) {
    return (
        <div
            className={classNames(inline && 'inline-block', 'form-control', wrapperClassName)}
            aria-invalid={errorMessage ? true : undefined}
        >
            <Label required={required}>{label}</Label>
            {children}
            <div className={`text-error h-[1rem]`}>{errorMessage}</div>
        </div>
    );
}
