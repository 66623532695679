export const extractDatefromSlideKey = (slideTableKey: string | undefined) => {
    const match = slideTableKey?.match(/(.*)_day(\d{1,2})_(.*)/);

    if (!match) {
        return { title: slideTableKey ?? '', day: '', date: '' };
    }
    const title = match?.[1] ?? '';
    const day = match?.[2] ?? '';
    const date = match?.[3] ?? '';
    return { title, day, date };
};
