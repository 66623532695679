import { createContext } from 'react';
import { CustomerConfig } from '@trawa-energy/offer-tool-api/deals/schemas';
import { UseTRPCQueryResult } from '@trpc/react-query/shared';

export const CustomConfigContext = createContext<
    | {
          config: CustomerConfig | undefined;
          isConfigLoading: boolean;
          isConfigError: boolean;
          refetch: UseTRPCQueryResult<CustomerConfig, unknown>['refetch'];
      }
    | undefined
>({
    config: undefined,
    isConfigLoading: false,
    isConfigError: false,
    refetch: () => new Promise(() => undefined),
});
