import { useMutation } from '@tanstack/react-query';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';

export function useUploadInputFile({ dealId, fileName }: { dealId: string; fileName: string }) {
    const { client } = trpc.useContext();

    const {
        mutateAsync: upload,
        isPending: isUploading,
        error,
    } = useMutation({
        mutationFn: async (content: Blob) => {
            return client.deals.getTempInputUploadUrl.query({ dealId, fileName }).then(loadUrl =>
                fetch(loadUrl, {
                    method: 'PUT',
                    body: content,
                    headers: {
                        'Content-Type': content.type,
                    },
                }),
            );
        },
    });

    return {
        upload,
        isUploading,
        error,
    };
}
