import { SlideTable } from '../types.ts';

export const calculateSumTabeRow = (table: SlideTable) => {
    const summaryRow = table.data.reduce((acc, row, tableIndex) => {
        row.forEach((cell, i) => {
            acc[i] = typeof cell === 'number' && typeof acc[i] === 'number' ? acc[i] + cell : acc[i];
        });
        return acc;
    }, new Array(table.columns.length).fill(0));
    return [undefined, ...summaryRow]; // first entry is an index row
};
