import { useIntl } from 'react-intl';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { useState } from 'react';
import classNames from 'classnames';
import { SlideView } from '../components/SlideView.tsx';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@trawa-energy/ui-kit';

export function OptionsOverview() {
    const { dealId, optionCategory, offerId } = useParams();
    const intl = useIntl();
    const [selectedTab, setSelectedTab] = useState('overview');

    if (!dealId || !offerId || !optionCategory) {
        throw new Error("Can't render OptionOverview without dealId, offerId or optionCategory");
    }

    const { data: deal } = trpc.deals.getDeal.useQuery({ dealId }, { enabled: !!dealId });
    const { data: slidesData } = trpc.deals.getSlides.useQuery(
        {
            dealId,
            offerId,
        },
        { enabled: !!dealId && !!offerId },
    );

    const { data: resultsData } = trpc.deals.getResults.useQuery(
        {
            dealId,
            offerId: offerId.toString(),
        },
        { enabled: !!dealId && !!offerId },
    );

    if (!slidesData || !resultsData) {
        return null;
    }

    const spotCategoryOrder = ['low', 'medium', 'high'];

    const slides = slidesData.slides.sort((a, b) => a.meta_data.spot_exposure - b.meta_data.spot_exposure);
    return (
        <div>
            <div className="my-8">
                <div className="pb-8 flex items-center gap-2">
                    <ArrowLeftIcon className="pt-1" />
                    <Link
                        to={`/deals/${dealId}/offers/${offerId}/summary`}
                        relative="path"
                        className="text-xl font-semibold"
                    >
                        {intl.formatMessage({ id: 'deals.optionsOutput.backToSummary' })}
                    </Link>
                </div>
                <h2 className="mb-8">
                    {intl.formatMessage(
                        { id: 'deals.optionsOutput.subHeading' },
                        {
                            offerName: resultsData.scenarios[0].meta_data.offer_name,
                            spotCategory: optionCategory,
                        },
                    )}
                </h2>
                <section>
                    <div role="tablist" className="tabs tabs-boxed flex flex-wrap">
                        {Object.keys(slides[0].result_data).map((slide, index) => (
                            <a
                                key={index}
                                role="tab"
                                className={classNames('tab flex-grow', {
                                    'tab-active': selectedTab === slide,
                                })}
                                onClick={() => setSelectedTab(slide)}
                            >
                                {intl.formatMessage({ id: `deals.optionsOutput.${slide}.title` })}
                            </a>
                        ))}
                    </div>
                </section>
                {Object.entries(slides[spotCategoryOrder.indexOf(optionCategory)].result_data).map(
                    ([slideName, value]) =>
                        selectedTab === slideName && (
                            <div key={slideName} className="mt-8">
                                <h2 className="text-xl">
                                    {intl.formatMessage({ id: `deals.optionsOutput.${slideName}.title` })}
                                </h2>
                                <SlideView value={value} name={slideName} />
                            </div>
                        ),
                )}
            </div>
        </div>
    );
}
