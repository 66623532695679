import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const contextClass = {
    success: 'bg-success',
    error: 'bg-error',
    info: 'bg-info',
    warning: 'bg-warning',
    default: 'bg-default',
};

export const ToastWrapper = () => {
    return (
        <ToastContainer
            toastClassName={context =>
                contextClass[context?.type || 'default'] +
                ' relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'
            }
            bodyClassName={() => 'flex text-sm font-white font-med p-3'}
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
};
