import { usePrompt } from '../hooks/usePrompt.ts';
import { useIntl } from 'react-intl';
import { useEffect, useRef } from 'react';

export const NavigationWarningModal = () => {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const intl = useIntl();
    /* usePrompt is using https://reactrouter.com/en/main/hooks/use-blocker */
    const blocker = usePrompt(true);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            dialogRef.current?.showModal();
        } else {
            dialogRef.current?.close();
        }
    }, [blocker]);

    return (
        <>
            {blocker.state === 'blocked' && (
                <dialog ref={dialogRef} className="modal">
                    <div className="modal-box">
                        <form method="dialog">
                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        </form>
                        <h3 className="font-bold text-lg">
                            {intl.formatMessage({ id: 'deals.createOffer.navigationPrompt.title' })}
                        </h3>
                        <p className="py-4">
                            {intl.formatMessage({ id: 'deals.createOffer.navigationPrompt.message' })}
                        </p>
                        <form method="dialog" className="modal-backdrop flex gap-4">
                            <button className="btn btn-md" onClick={() => blocker?.reset()}>
                                {intl.formatMessage({ id: 'deals.createOffer.navigationPrompt.cancel' })}
                            </button>
                            <button className="btn btn-md" onClick={() => blocker?.proceed()}>
                                {intl.formatMessage({ id: 'deals.createOffer.navigationPrompt.confirm' })}
                            </button>
                        </form>
                    </div>
                </dialog>
            )}
        </>
    );
};
