import { useIntl } from 'react-intl';
import { HourGlassIcon, SuccessTickIcon, WarningTriangleIcon } from './Icons.tsx';

export const PresentationItem = ({
    fileName,
    template = 'Template',
    fileUrl,
    status,
}: {
    template: string;
    fileName?: string;
    fileUrl?: string;
    status: string;
}) => {
    const intl = useIntl();
    if (status === 'running') {
        return (
            <div className="text-left flex gap-2 items-center my-2">
                <HourGlassIcon width={24} height={24} />
                <div className="skeleton h-8 w-full px-4 py-2 overflow-auto text-ellipsis">{template}</div>
            </div>
        );
    }

    return (
        <div className="text-left my-2">
            <p className="text-base font-semibold text-gray-700 text-ellipsis overflow-auto">{template}</p>
            <p className="inline-flex font-normal items-center gap-2 my-2 text-ellipsis">
                <span>{status === 'success' ? <SuccessTickIcon /> : <WarningTriangleIcon />}</span>
                {fileName && fileUrl ? (
                    <a
                        className="link text-base text-gray-700 break-all whitespace-normal"
                        target="_blank"
                        href={fileUrl}
                    >
                        {fileName}
                    </a>
                ) : (
                    <span className=" text-base text-gray-700">
                        {intl.formatMessage({ id: 'deals.summary.options.results.tableFooter.noFile' })}
                    </span>
                )}
            </p>
        </div>
    );
};
