import classNames from 'classnames';
import { forwardRef } from 'react';

export const TextInputField = forwardRef(
    (
        {
            label,
            placeholder,
            hasError,
            isDisabled,
            bottomLeftErrorLabel,
            bottomLeftLabel,
            bottomRightLabel,
            isFullWidth,
            showInput = true,
            ...registerProps
        }: {
            label: string;
            placeholder: string;
            bottomLeftErrorLabel?: string;
            bottomLeftLabel?: string | number;
            bottomRightLabel?: string;
            hasError?: boolean;
            isFullWidth?: boolean;
            isDisabled?: boolean;
            showInput?: boolean;
        },
        ref: React.ForwardedRef<HTMLInputElement>,
    ) => (
        <label className={classNames('form-control w-full', { 'max-w-xs': !isFullWidth })}>
            <div className="label">
                <span className="label-text font-bold text-base text-stone-500">{label}</span>
            </div>
            <input
                ref={ref}
                type="text"
                disabled={!!isDisabled}
                placeholder={placeholder}
                className={classNames('input input-bordered input-md w-full', {
                    'input-error': hasError,
                    'max-w-xs': !isFullWidth,
                    hidden: !showInput,
                })}
                {...registerProps}
            />
            <div className="label">
                {hasError ? (
                    <span className={classNames('label-text-alt', { 'text-warning-content': hasError })}>
                        {bottomLeftErrorLabel}
                    </span>
                ) : (
                    <span className={'label-text-alt'}>{bottomLeftLabel}</span>
                )}
                {bottomRightLabel && <span className="label-text-alt">{bottomRightLabel}</span>}
            </div>
        </label>
    ),
);

TextInputField.displayName = 'TextInputField';
