import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import React, { useState } from 'react';
import { trpcBackoffice as trpc, trpcOffertool } from './utils/trpc';
import { useAuthContext } from '@trawa-energy/authentication-ui';
import { config } from './config';

export default function AuthTRpcProviders({ children }: { children: React.ReactElement | React.ReactElement[] }) {
    const { getAccessToken } = useAuthContext();
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
            }),
    );
    const [trpcClient] = useState(() =>
        trpc.createClient({
            links: [
                httpBatchLink({
                    url: config.clientsApiBaseUrl,
                    async headers() {
                        return {
                            authorization: `Bearer ${await getAccessToken()}`,
                        };
                    },
                }),
            ],
        }),
    );

    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </trpc.Provider>
    );
}

export function AuthTRpcProvidersOfferTool({ children }: { children: React.ReactElement | React.ReactElement[] }) {
    const { getAccessToken } = useAuthContext();

    const [queryClientOfferTool] = useState(
        () =>
            new QueryClient({
                defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
            }),
    );

    const [trpcClientOfferTool] = useState(() =>
        trpcOffertool.createClient({
            links: [
                httpBatchLink({
                    url: config.clientOfferToolApiBaseUrl,
                    async headers() {
                        return {
                            authorization: `Bearer ${await getAccessToken()}`,
                        };
                    },
                }),
            ],
        }),
    );

    return (
        <trpcOffertool.Provider client={trpcClientOfferTool} queryClient={queryClientOfferTool}>
            <QueryClientProvider client={queryClientOfferTool}>{children}</QueryClientProvider>
        </trpcOffertool.Provider>
    );
}
