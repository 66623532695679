import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { trpcBackoffice as trpc } from '../utils/trpc';
import { useEffect, useState } from 'react';
import TextInput from '../shared/inputs/TextInput';
import { Field } from '../shared/field/Field';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { fakeAccountIds } from './fakeAccountIds';

const userFormValuesSchema = z.object({
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
});
const resolver = zodResolver(userFormValuesSchema);

export type UserFormValues = z.infer<typeof userFormValuesSchema>;

export function UserForm({
    userId,
    accountId,
    className,
    onSaved,
}: {
    userId?: string;
    accountId?: string;
    className?: string;
    onSaved?: () => void;
}) {
    const navigate = useNavigate();
    const { mutateAsync: saveDefault, isPending: pendingSaveDefault } = trpc.users.saveDefault.useMutation();
    const { mutateAsync: saveDemo, isPending: pendingSaveDemo } = trpc.users.saveDemo.useMutation();

    const [serverError, setServerError] = useState<string>();

    const { data: user, isLoading } = trpc.users.getById.useQuery({ id: userId! }, { enabled: !!userId });
    const { mutateAsync: deleteAsync, isPending: pendingDelete } = trpc.users.delete.useMutation();

    const methods = useForm<UserFormValues>({
        resolver,
        defaultValues: {
            email: '',
            firstName: '',
            lastName: '',
        },
    });

    useEffect(() => {
        if (user) {
            const { id, ...userFields } = user;
            methods.reset(userFields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!user]);

    const onSubmit = async (data: UserFormValues) => {
        const resolvedAccountId = user?.accountId ?? accountId;
        if (!resolvedAccountId) {
            throw new Error('Missing accountId');
        }

        try {
            if (fakeAccountIds.includes(resolvedAccountId)) {
                // these will get proper emails with a temp password
                await saveDemo({ ...data, accountId: resolvedAccountId, id: userId });
            } else {
                await saveDefault({ ...data, accountId: resolvedAccountId, id: userId });
            }
            onSaved?.();
        } catch (error) {
            if (error instanceof Error) {
                setServerError(error.message);
            } else {
                console.error(error);
                setServerError('Unknown error');
            }
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className={classNames(className, 'space-y-5')}>
                <h3 className="text-lg">{user ? 'Edit user' : 'Add user'}</h3>
                {isLoading ? (
                    <fieldset className="space-y-4">
                        <div className="h-20 skeleton"></div>
                        <div className="h-20 skeleton"></div>
                        <div className="h-20 skeleton"></div>
                    </fieldset>
                ) : (
                    <fieldset className="space-y-4">
                        <Field component={TextInput} required name="firstName" label="First name" />
                        <Field component={TextInput} required name="lastName" label="Last name" />
                        <Field component={TextInput} required name="email" label="Email" />
                    </fieldset>
                )}
                {serverError && <div className="alert alert-error">{serverError}</div>}
                <div className="space-x-4">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={pendingSaveDefault || pendingSaveDemo || pendingDelete}
                    >
                        Save
                    </button>
                    {user && (
                        <button
                            className="btn btn-error"
                            disabled={pendingSaveDefault || pendingSaveDemo || pendingDelete}
                            onClick={async () => {
                                if (confirm('Are you sure?')) {
                                    await deleteAsync({ id: user.id });
                                    navigate(`/users?accountId=${user.accountId}`);
                                }
                            }}
                        >
                            Delete
                        </button>
                    )}
                </div>
            </form>
        </FormProvider>
    );
}
